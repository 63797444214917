<script>
import Banner from "@/views/components/Banner.vue";
import Breadcrumb from "@/views/components/Breadcrumb.vue";
import LeftMenu from "@/views/components/LeftMenu.vue";

export default {
  name: "index",
  components: { Breadcrumb, Banner, LeftMenu },
  data() {
    return {
      tabsOption: [
        {
          key: "insurance",
          title: "金融保险",
          desc:
            "<span>艺术品保险是在传统的财产保险和货运保险的基础上演变和发展而来的，主要承保火灾以及其他自然灾害和意外事故造成的艺术品直接损失。<br /><br />" +
            " 保险标的包括投保人所有或代他人保管或其他人共有而由投保人负责保管、展览、装卸、运输的艺术品。博物馆、展览馆、艺术品拍卖行、艺术品公司、文化艺术公司、运输公司等均可投保。<br />" +
            "   艺术品在保管收藏、展览、运输、装卸等过程中会遇到多种风险，有自然风险，如：地震、暴雨、洪水、暴风、冰雹等：有意外事故，如：火灾、爆炸、盗抢、水淹、运输工具发生意外、包装破裂、装卸中疏忽损坏、碰撞等。<br /><br />" +
            "   据调查，艺术品的损失比例大概为：40%的运送与装卸损失，38%的盗窃损失，18%的火灾、水渍、烟熏等损失，4%的光线、温湿度、诈骗、地震、暴风、闪电等损失。可以通过避免、预防、分散、转移等方法进行风险管理，保险则是控制风险最直接有效的方法。\n" +
            "   通过投保，不但可以在发生损失时获得经济补偿，更重要的是通过投保获得保险公司的风险管理服务。</span>",
          img: require("@/assets/service/bx_bg.png"),
          menuChild: [
            {
              key: "pingan",
              title: "平安保险",
              img: require("@/assets/service/bx_01.jpg"),
              desc:
                "<span>平安保险：专业的艺术品保险服务<br /><br />" +
                "中国平安保险（集团）股份有限公司（以下简称“平安保险”），作为中国艺术银行旗下的一站式综合金融服务提供商，不仅以其丰富的金融保险经验为艺术品市场保驾护航，更通过不断创新与深化服务，引领着艺术与金融融合的新趋势。<br /><br />" +
                "平安保险通过设立专门的艺术金融部门，为艺术品收藏家、艺术机构及艺术家提供全方位的金融服务，包括但不限于艺术品保险、融资贷款、投资顾问、遗产规划等。这些服务不仅解决了艺术品市场中的资金流动性问题，还通过金融手段促进了艺术品的价值发现和流通。<br /><br />" +
                "平安保险针对艺术品收藏家或投资者的融资需求，提供了灵活的融资贷款服务，帮助他们解决资金瓶颈问题，实现艺术品的快速流通和价值变现。此外，平安保险还通过设立艺术品投资基金等方式，引导社会资本进入艺术品市场，推动艺术品的产业化发展。</span>"
            },
            {
              key: "taiping",
              title: "太平保险",
              img: require("@/assets/service/bx_02.jpg"),
              desc:
                "<span>太平保险针对艺术银行业务概览<br /><br />" +
                "作为艺术银行旗下重要的金融保险机构，太平保险以其深厚的保险底蕴为艺术品市场提供坚实的保障，满足市场多元化需求。<br /><br /></span>" +
                "艺术银行业务在太平保险中，被巧妙地融入其私人财富管理部门，通过专业团队为客户提供从艺术品收藏、价值评估、遗产规划到收藏管理的全方位服务。<br /><br /></span>" +
                "艺术银行的保险业务首先体现在收藏体系规划上，借助多年艺术收藏运作的经验和国际化的网络平台，太平保险能够为客户寻找高质量、保值的艺术品，并提供价值评估、鉴定和相关文件服务，确保艺术品的真实性和价值。在价值保存方面，太平保险不仅提供艺术资产的修复、运输和保险方面的建议，还为客户提供个人基金会筹建、艺术品捐献等建议和规划，确保艺术品的长期保存和价值传承。<br /><br /></span>"
            }
          ]
        },
        {
          key: "artPackage",
          title: "艺术资产包",
          img: require("@/assets/service/art_bg.jpg"),
          desc:
            "<span>目前，已有的艺术品银行通常由政府提供资金支持或政策保障，通过购买本土年轻艺术家的作品，将作品租赁给政府机关、企业机构、个人等，让艺术品走进生活。<br /><br />" +
            "            在亚洲，日本、韩国、新加坡、印度和中国等也都先后成立了艺术品银行。我国台湾地区继韩国之后开设了亚洲地区第2家由政府主导的艺术品银行。在政府的大力支持下，不少政府机关、机场、医院和企业租赁了艺术品银行的作品，为艺术品市场注入了新的动力。<br /><br />" +
            "            最近10年，上海、北京、广州等地也相继成立了艺术品租赁的分支机构。艺术品租赁市场主要在公司机构，通过定期化、专业化、定制化和批量化供给，搭建机构、艺术家和作品的信息和交流互动，持续推动艺术品教育，丰富艺术品银行的产品线和服务链。" +
            "            实现艺术品银行、艺术家和租赁机构三方的多赢，逐步形成较好的商业模式。</span>",
          menuChild: [
            {
              key: "lease",
              title: "金融租赁",
              img: require("@/assets/service/art_01.jpg"),
              desc:
                "<span>目前，已有的艺术品银行通常由政府提供资金支持或政策保障，通过购买本土年轻艺术家的作品，将作品租赁给政府机关、企业机构、个人等，让艺术品走进生活。<br /><br />" +
                "            在亚洲，日本、韩国、新加坡、印度和中国等也都先后成立了艺术品银行。我国台湾地区继韩国之后开设了亚洲地区第2家由政府主导的艺术品银行。在政府的大力支持下，不少政府机关、机场、医院和企业租赁了艺术品银行的作品，为艺术品市场注入了新的动力。<br /><br />" +
                "            最近10年，上海、北京、广州等地也相继成立了艺术品租赁的分支机构。艺术品租赁市场主要在公司机构，通过定期化、专业化、定制化和批量化供给，搭建机构、艺术家和作品的信息和交流互动，持续推动艺术品教育，丰富艺术品银行的产品线和服务链。" +
                "            实现艺术品银行、艺术家和租赁机构三方的多赢，逐步形成较好的商业模式。</span>"
            },
            {
              key: "factor",
              title: "金融保理",
              img: require("@/assets/service/art_02.jpg"),
              desc:
                "<span>金融保理<br /><br />" +
                "中国艺术银行旗下的金融保理服务，主要围绕艺术品市场的特殊需求，提供了一系列专业的金融服务。<br /><br />" +
                "    由于艺术品具有极高的价值和独特性，其面临的风险也相对较高。因此，中国艺术银行与专业的保险公司合作，为艺术品提供全面的保险保障。这些保险通常覆盖艺术品在运输、展览、保管等各个环节可能遭受的损失，如火灾、盗窃、自然灾害等。<br /><br />" +
                "    艺术品的专业保理是一个复杂而细致的过程，涉及不同材质、不同种类的艺术品，因此其保养方法也各不相同。此外，中国艺术银行通过设立艺术品信托计划或基金，吸引更多的社会资本进入艺术品市场，推动艺术品的产业化发展。</span>"
            },
            {
              key: "service",
              title: "金融服务",
              img: require("@/assets/service/art_03.jpg"),
              desc:
                "<span>艺术品与股票、房地产被认为是三大投资资产。艺术品投资市场相对于其他资产市场而言，一直以来都是高净值人群的小众市场。<br /><br />" +
                "近年来，伴随着中国经济的快速发展，中国艺术品市场交易实现了从数十亿元到上千亿元规模的质的跨越。<br />" +
                "中国艺术品市场已经成为世界艺术品市场的重要组成部分。艺术品价格指数、艺术品抵押、艺术品信托、艺术品基金、艺术品保险和艺术品交易所等艺术品金融产品的创新层出不穷，市场非常活跃。<br />" +
                "从金融服务业层面上来看，在经济“调结构、促转型”的大背景下，金融业面临实体经济下行的压力。传统的工业和房地产等产能过剩行业的信贷资产质量已进入下降通道，信贷资产质量管控的压力越来越大，使得金融服务业既有的盈利基础面临巨大挑战。<br />" +
                "加之政府监管的调整、市场竞争的加剧以及互联网金融模式的冲击，金融业无疑需要拓展金融市场的广度和深度。<br /><br />" +
                "  为此，艺术品金融应以高净值人群的财富配置需求偏好和文化艺术资产管理为切入点，在金融服务品质方面创新改革，盘活存量巨大的艺术品“表外资产”，同时结合文化艺术产业生态发展的市场需求，对金融领域的繁荣发展将带来巨大的促进作用。</span>"
            }
          ]
        }
      ],
      currentTab: {
        key: "insurance",
        title: "金融保险",
        desc:
          "<span>艺术品保险是在传统的财产保险和货运保险的基础上演变和发展而来的，主要承保火灾以及其他自然灾害和意外事故造成的艺术品直接损失。<br /><br />" +
          " 保险标的包括投保人所有或代他人保管或其他人共有而由投保人负责保管、展览、装卸、运输的艺术品。博物馆、展览馆、艺术品拍卖行、艺术品公司、文化艺术公司、运输公司等均可投保。<br />" +
          "   艺术品在保管收藏、展览、运输、装卸等过程中会遇到多种风险，有自然风险，如：地震、暴雨、洪水、暴风、冰雹等：有意外事故，如：火灾、爆炸、盗抢、水淹、运输工具发生意外、包装破裂、装卸中疏忽损坏、碰撞等。<br /><br />" +
          "   据调查，艺术品的损失比例大概为：40%的运送与装卸损失，38%的盗窃损失，18%的火灾、水渍、烟熏等损失，4%的光线、温湿度、诈骗、地震、暴风、闪电等损失。可以通过避免、预防、分散、转移等方法进行风险管理，保险则是控制风险最直接有效的方法。\n" +
          "   通过投保，不但可以在发生损失时获得经济补偿，更重要的是通过投保获得保险公司的风险管理服务。</span>",
        img: require("@/assets/service/art_01.jpg"),
        menuChild: [
          {
            key: "pabx",
            title: "平安保险",
            img: require("@/assets/service/bx_01.jpg"),
            desc:
              "<span>平安保险：专业的艺术品保险服务<br /><br />" +
              "中国平安保险（集团）股份有限公司（以下简称“平安保险”），作为中国艺术银行旗下的一站式综合金融服务提供商，不仅以其丰富的金融保险经验为艺术品市场保驾护航，更通过不断创新与深化服务，引领着艺术与金融融合的新趋势。<br /><br />" +
              "平安保险通过设立专门的艺术金融部门，为艺术品收藏家、艺术机构及艺术家提供全方位的金融服务，包括但不限于艺术品保险、融资贷款、投资顾问、遗产规划等。这些服务不仅解决了艺术品市场中的资金流动性问题，还通过金融手段促进了艺术品的价值发现和流通。<br /><br />" +
              "平安保险针对艺术品收藏家或投资者的融资需求，提供了灵活的融资贷款服务，帮助他们解决资金瓶颈问题，实现艺术品的快速流通和价值变现。此外，平安保险还通过设立艺术品投资基金等方式，引导社会资本进入艺术品市场，推动艺术品的产业化发展。</span>"
          },
          {
            key: "tpybx",
            title: "太平保险",
            img: require("@/assets/service/bx_02.jpg"),
            desc:
              "<span>太平保险针对艺术银行业务概览<br /><br />" +
              "作为艺术银行旗下重要的金融保险机构，太平保险以其深厚的保险底蕴为艺术品市场提供坚实的保障，满足市场多元化需求。<br /><br /></span>" +
              "艺术银行业务在太平保险中，被巧妙地融入其私人财富管理部门，通过专业团队为客户提供从艺术品收藏、价值评估、遗产规划到收藏管理的全方位服务。<br /><br /></span>" +
              "艺术银行的保险业务首先体现在收藏体系规划上，借助多年艺术收藏运作的经验和国际化的网络平台，太平保险能够为客户寻找高质量、保值的艺术品，并提供价值评估、鉴定和相关文件服务，确保艺术品的真实性和价值。在价值保存方面，太平保险不仅提供艺术资产的修复、运输和保险方面的建议，还为客户提供个人基金会筹建、艺术品捐献等建议和规划，确保艺术品的长期保存和价值传承。<br /><br /></span>"
          }
        ]
      },
      breadcrumbOption: [],
      childSelect: {}, // 三级目录选择的
      rawHtml: '<span style="color: red">This should be red.</span>'
    };
  },
  mounted() {
    // this.currentTab = this.tabsOption[0];
    this.childSelect = this.tabsOption[0];
    this.checkTab(this.currentTab.key);
  },
  methods: {
    /**
     * 切换一级目录
     * @param key
     */
    checkTab(key) {
      // 初始化三级目录
      this.childSelect = {};
      const tabsOption = this.tabsOption;
      tabsOption.map(item => {
        if (item.key === key) {
          this.currentTab = item;
          // 添加面包屑
          const currentOption = [
            {
              key: "financialService",
              toPath: "/financialService"
            }
          ];
          currentOption.push({
            key: item.key,
            title: item.title,
            toPath: "/financialService"
          });
          this.breadcrumbOption = currentOption;
          console.log("breadcrumbOption", this.breadcrumbOption);
        }
      });
    },
    checkChildSelect(item) {
      const currentOption = this.breadcrumbOption;
      const newOption = {
        key: item.key,
        title: item.title,
        toPath: "/financialService"
      };
      currentOption.splice(2, 3, newOption);
      this.breadcrumbOption = currentOption;
      this.childSelect = item;
    }
  }
};
</script>

<template>
  <div class="">
    <Banner
      :bannerSrc="require('@/assets/banner/service.jpg')"
      :tabs="tabsOption"
      :selTab="currentTab.key"
    />
    <div class="padding-comm-warp _content_warp service_content_warp">
      <div class="left">
        <LeftMenu :currentTab="currentTab" :childSelect="childSelect" />
      </div>
      <div class="right">
        <Breadcrumb :breadcrumbList="breadcrumbOption" />
        <div class="content">
          <el-image
            :src="
              childSelect && Object.keys(childSelect).length !== 0
                ? childSelect.img
                : currentTab.img
            "
            lazy
          />
          <div
            class="desc"
            v-html="
              childSelect && Object.keys(childSelect).length !== 0
                ? childSelect.desc
                : currentTab.desc
            "
          >
            <!--
           v-bind:innerHTML="-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";

.service_content_warp .right .content .desc {
  margin: 20px 0 0px;
  line-height: 30px;
}
</style>
