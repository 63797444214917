var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('Banner',{attrs:{"bannerSrc":require('@/assets/banner/service.jpg'),"tabs":_vm.tabsOption,"selTab":_vm.currentTab.key}}),_c('div',{staticClass:"padding-comm-warp _content_warp service_content_warp"},[_c('div',{staticClass:"left"},[_c('LeftMenu',{attrs:{"currentTab":_vm.currentTab,"childSelect":_vm.childSelect}})],1),_c('div',{staticClass:"right"},[_c('Breadcrumb',{attrs:{"breadcrumbList":_vm.breadcrumbOption}}),_c('div',{staticClass:"content"},[_c('el-image',{attrs:{"src":_vm.childSelect && Object.keys(_vm.childSelect).length !== 0
              ? _vm.childSelect.img
              : _vm.currentTab.img,"lazy":""}}),_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(
            _vm.childSelect && Object.keys(_vm.childSelect).length !== 0
              ? _vm.childSelect.desc
              : _vm.currentTab.desc
          )}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }